import OAuthRoutes from '../../../../vendor/z5internet/ruf_oauth/src/resources/assets/js/src/app/Auth/Routes';

import asyncComponent from 'Demora/AsyncComponent';

const AuthHome = asyncComponent(() =>
	import(/* webpackChunkName: 'LTAuthHome' */ '../components/AuthHome')
);

const Settings = asyncComponent(() =>
	import(
		/* webpackChunkName: "prot-auth-settings" */ '../../../../vendor/z5internet/demora/src/resources/assets/js/src/app/Auth/Settings/Settings'
	)
);

const Profile = asyncComponent(() =>
	import(
		/* webpackChunkName: "prot-auth-settings" */ '../../js/components/User/Profile'
	)
);

const Test = asyncComponent(() =>
	import(
		/* webpackChunkName: "prot-auth-settings" */ '../../js/components/LandingPage/index'
	)
);

const Partners = asyncComponent(() =>
	import(
		/* webpackChunkName: "prot-auth-settings" */ '../../js/components/Partners/Partners'
	)
);

export default OAuthRoutes.concat([
	{
		path: '/home/:tab/:tab1?/:tab2?',
		component: AuthHome,
	},
	{
		path: '/settings/emailSettings',
		component: Settings,
	},
	{
		path: '/settings/notifications',
		component: Settings,
	},
	{
		path: '/profile/:uid',
		component: Profile,
	},
	{
		path: '/partner/stats',
		component: Partners,
	},
	{
		path: '/test/home',
		component: Test,
	},
]);
