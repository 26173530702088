import React from 'react';
import { Link } from 'react-router-dom';

import './Footer.scss';

class FooterNavBar extends React.Component {
	render() {
		let cols = 'col-4';

		return (
			<div style={{ flexGrow: 1, lineHeight: '24px' }} id='footer'>
				<div className='footer-top'>
					<div className='container d-block'>
						<div className='row'>
							<div className='col-lg-4 col-md-12 footer-info'>
								<h3>LinkTracker Live</h3>
								<p>
									Online tools for affiliate & network
									marketers.
								</p>
							</div>

							<div className='col-lg-4 col-md-12 footer-links'>
								<h4>Useful Links</h4>
								<ul>
									<li>
										<Link to='/'>Home</Link>
									</li>
									<li className='d-none'>
										<Link to='#' rel='nofollow'>
											About us
										</Link>
									</li>
									<li className='d-none'>
										<Link to='#' rel='nofollow'>
											Services
										</Link>
									</li>
									<li>
										<Link to='/terms' rel='nofollow'>
											Terms of service
										</Link>
									</li>
									<li>
										<Link to='/privacy' rel='nofollow'>
											Privacy policy
										</Link>
									</li>
								</ul>
							</div>

							<div className='col-lg-4 col-md-12 footer-contact'>
								<h4>Contact Us</h4>
								<p>
									z5 Internet Ltd, registered in the UK
									(05294840)
									<br />
									23 Benham Grove, Portchester, PO16 9LB
									<br />
									<Link to='/contact' rel='nofollow'>
										<strong>Email us</strong>
									</Link>
									<br />
									<strong>Customer Service: </strong>+44 2392
									382731
								</p>

								<div className='social-links d-none'>
									<a
										href='#'
										className='twitter'
										rel='nofollow'
									>
										<i className='icofont-twitter'></i>
									</a>
									<a
										href='#'
										className='facebook'
										rel='nofollow'
									>
										<i className='icofont-facebook'></i>
									</a>
									<a
										href='#'
										className='instagram'
										rel='nofollow'
									>
										<i className='icofont-instagram'></i>
									</a>
									<a
										href='#'
										className='google-plus'
										rel='nofollow'
									>
										<i className='icofont-skype'></i>
									</a>
									<a
										href='#'
										className='linkedin'
										rel='nofollow'
									>
										<i className='icofont-linkedin'></i>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='bg-primary'>
					<div className='copyright'>
						&copy; Copyright 2020-{new Date().getFullYear()}{' '}
						<strong>
							<span>LinkTracker Live</span>
						</strong>{' '}
						z5 Internet Ltd. All Rights Reserved
					</div>
					<div className='credits'>
						Designed by{' '}
						<a href='https://bootstrapmade.com/' rel='nofollow'>
							BootstrapMade
						</a>
						<br />
						Icons made by{' '}
						<a
							href='https://www.flaticon.com/authors/freepik'
							title='Freepik'
							rel='nofollow'
						>
							Freepik
						</a>{' '}
						from{' '}
						<a
							href='https://www.flaticon.com/'
							title='Flaticon'
							rel='nofollow'
						>
							www.flaticon.com
						</a>
					</div>
				</div>
			</div>
		);
	}
}

export default FooterNavBar;
