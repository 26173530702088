const ad_stuff = (state = {}, action) => {
	switch (action.type) {
		case 'STORE_AD_STUFF':
			let da = action.data;

			if (da.countries) {
				da.user_urls_countries = Object.assign({}, da.countries);

				delete da.countries;
			}

			if (da.languages) {
				da.user_urls_languages = Object.keys(da.languages).map(
					(code) => {
						return { value: code, label: da.languages[code] };
					}
				);

				da.user_urls_languages.unshift({
					value: '',
					label: '',
					disabled: true,
				});
				da.user_urls_languages.unshift({
					value: 'ru',
					label: da.languages.ru,
				});
				da.user_urls_languages.unshift({
					value: 'fr',
					label: da.languages.fr,
				});
				da.user_urls_languages.unshift({
					value: 'de',
					label: da.languages.de,
				});
				da.user_urls_languages.unshift({
					value: 'es',
					label: da.languages.es,
				});
				da.user_urls_languages.unshift({
					value: 'en',
					label: da.languages.en,
				});

				delete da.languages;
			}

			if (da.credits) {
				da.total_credits = da.credits;

				delete da.credits;
			}

			if (da.ads) {
				if (!state.ads) {
					state.ads = {};
				}

				da.ads.map((ad) => {
					state.ads[ad.id] = ad;
				});

				da.ads = Object.assign({}, state.ads);
			}

			for (let i in da) {
				state[i] = da[i];
			}

			return Object.assign({}, state);

		case 'DELETE_AD':
			delete state.ads[action.ad_id];

			return Object.assign({}, state);

		default:
			return state;
	}
};

const urls = (state = {}, action) => {
	switch (action.type) {
		case 'STORE_AD_STUFF':
			return Object.assign({}, state, action.data.urls);

		default:
			return state;
	}
};

const joyride = (state = {}, action) => {
	switch (action.type) {
		case 'JOYRIDE_START':
			state.started = true;

			return Object.assign({}, state);

		case 'JOYRIDE_STOP':
			state.started = false;

			return Object.assign({}, state);

		default:
			return state;
	}
};

export default {
	ad_stuff,
	joyride,
	urls,
};
