import React from 'react';

import ReduxConnect from 'Demora/redux-connect';

import asyncComponent from 'Demora/AsyncComponent';

import { Link, withRouter } from 'react-router-dom';

import classNames from 'classnames';

import isUpgraded from '../../js/components/upgrade/pay/isUpgraded';

const ReactBurgerMenu = asyncComponent(() =>
	import(
		/* webpackChunkName: "react-burger-menu" */ 'react-burger-menu/lib/menus/push'
	)
);

class PreHeader extends React.Component {
	constructor(props) {
		super();

		this.state = {
			menuOpen: false,
			navigation: null,
			loaded: true,
			smallScreen: true,
		};
	}

	checkSmallScreen = () => {
		const props = this.props;

		const over800 = window.innerWidth > 800;

		this.setState({
			smallScreen: !over800,
		});

		const op =
			!new URLSearchParams(this.props.location.search).get('tour') &&
			!props.location.pathname.match(/getStarted/) &&
			!!(over800 && this.props.user.id);

		if (!op) {
			this.changeMenu(false);
		}
	};

	changeMenu = (state) => {
		this.setState(
			{
				menuOpen: state,
			},
			this.changeCSS
		);
	};

	menuStateChange = (t) => {
		this.changeMenu(t.isOpen);
	};

	changeCSS() {
		const el = document.body;

		if (this.state.menuOpen) {
			el.classList.add('menu-open');
		} else {
			el.classList.remove('menu-open');
		}
	}

	getNavigation() {
		const props = this.props;

		const user = props.user;

		if (
			!user.id ||
			new URLSearchParams(location.search).get('hide-header') ||
			props.location.pathname.match(/getStarted/)
		) {
			return;
		}

		let navigation = [];

		if (isUpgraded() < 2) {
			navigation = navigation.concat({
				heading: 'Upgrade',
			});

			navigation = navigation.concat({
				url: '/home/upgrade',
				icon: '',
				name: <span className='btn btn-danger'>Upgrade Now</span>,
			});
		}

		navigation = navigation.concat([
			{
				heading: 'LinkTracker',
			},
			{
				url: '/home/linkTracker',
				icon: 'fa fa-home',
				name: 'Links',
			},
			{
				url: '/home/linkTrackerStats',
				icon: 'fas fa-tachometer-alt',
				name: 'Stats',
			},
			{
				heading: 'Landing Page',
			},
			{
				url: '/home/landingPage',
				icon: 'fas fa-file-alt',
				name: 'Landing Page',
			},
			{
				heading: 'File Hosting',
			},
			{
				url: '/home/fileHosting',
				icon: 'fas fa-folder-open',
				name: 'File Hosting',
			},
			{
				heading: 'Ads',
			},
			{
				url: '/home/ads',
				icon: 'fa fa-home',
				name: 'Your Ads',
			},
			{
				url: '/home/adStats',
				icon: 'fas fa-tachometer-alt',
				name: 'Ads Stats',
			},
		]);

		navigation = navigation.concat([
			{
				heading: 'Account settings',
			},
			{
				url: '/settings',
				icon: 'fa fa-cogs',
				name: 'Settings',
			},
			{
				url: '/settings/security',
				icon: 'fas fa-shield-alt',
				name: 'Change password',
			},
			{
				heading: 'Legal',
			},
			{
				url: '/terms',
				icon: 'fas fa-gavel',
				name: 'Terms',
			},
			{
				url: '/privacy',
				icon: 'fa fa-user-secret',
				name: 'Privacy',
			},
			{
				heading: 'Help',
			},
			{
				url: '/contact',
				icon: 'far fa-envelope',
				name: 'Contact us',
			},
		]);

		this.setState({
			navigation,
		});
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.user.id !== prevProps.user.id ||
			this.props.location !== prevProps.location
		) {
			this.navigation = this.getNavigation();
			this.checkSmallScreen();
		}
	}

	componentDidMount() {
		this.navigation = this.getNavigation();
		this.checkSmallScreen();
		window.addEventListener('resize', this.checkSmallScreen);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.checkSmallScreen);
	}

	render() {
		const state = this.state;

		const props = this.props;

		const styles = {
			bmBurgerButton: {
				position: 'fixed',
				width: '36px',
				height: '30px',
				top: '17px',
				left: '13px',
			},
			bmBurgerBars: {
				background: '#ffffff',
			},
			bmCrossButton: {
				height: '24px',
				width: '24px',
			},
			bmCross: {
				background: '#fff',
			},
			bmMenu: {
				background: '#191938',
			},
			bmItem: {
				color: '#ffffff',
			},
			bmMorphShape: {
				fill: '#191938',
			},
			bmItemList: {
				color: '#191938',
				padding: '0.8em',
			},
			bmOverlay: {
				background: 'rgba(0, 0, 0, 0.3)',
			},
			bmMenuWrap: {
				top: 0,
				left: 0,
				width: '220px',
			},
		};

		let show = true;

		const user = props.user;

		if (
			!state.navigation ||
			!state.loaded ||
			!user.id ||
			!user.finishedGetStarted ||
			location.pathname.match(/^\/getStarted/)
		) {
			show = false;
		}

		if (show) {
			return (
				<div
					style={{
						zIndex: 2000,
						position: 'fixed',
						top: 0,
					}}
				>
					<ReactBurgerMenu
						id='joyride_menu_button'
						outerContainerId={'appRoot'}
						pageWrapId={'pageContent'}
						styles={styles}
						isOpen={state.menuOpen}
						onStateChange={this.menuStateChange}
						noOverlay
						width={220}
					>
						{
							<div className='text-center'>
								<h3 style={{ marginLeft: '-20px' }}>
									<img
										src='/assets/logo_3.jpg'
										style={{ height: '34px' }}
									/>
								</h3>
							</div>
						}

						{state.navigation.map((n, k) => {
							if (n.heading) {
								return (
									<div
										style={{
											margin: '16px 0 8px',
											borderTop: '1px solid #777',
											paddingTop: '16px',
										}}
										key={k}
										className='menu-item'
									>
										{n.heading}
									</div>
								);
							}

							return (
								<Link
									id={n.name}
									key={k}
									className='menu-item'
									to={n.url}
									onClick={() => {
										if (state.smallScreen) {
											this.changeMenu(false);
										}
									}}
								>
									<i
										style={{
											paddingLeft: '17px',
											color: '#777',
										}}
										className={classNames('fa-fw', n.icon)}
									></i>
									<span style={{ paddingLeft: '20px' }}>
										{n.name}
									</span>
								</Link>
							);
						})}

						<a
							id='logout'
							className='menu-item'
							style={{ color: '#ffffff' }}
							href='/logout'
						>
							<i
								style={{ paddingLeft: '17px', color: '#777' }}
								className='fas fa-fw fa-btn fa-sign-out-alt'
							/>
							<span style={{ paddingLeft: '20px' }}>Logout</span>
						</a>

						<div className='pt-5'></div>
					</ReactBurgerMenu>
				</div>
			);
		}

		return null;
	}
}

export default withRouter(
	ReduxConnect(PreHeader, {
		user: 'user',
	})
);
