const isUpgraded = () => {
	const user = window.reduxStore.getState().user;

	if (!user.currentTeam || !user.multiAccounts) {
		return 0;
	}

	return user.finishedGetStarted.upgraded;
};

export default isUpgraded;
