import asyncComponent from 'Demora/AsyncComponent';

const Signup = asyncComponent(() =>
	import(
		/* webpackChunkName: "specialinvite" */ '../../js/components/SpecialSignupForm'
	)
);

export default [
	{
		path: '/signup/specialinvite',
		component: Signup,
	},
	{
		path: '/settings/emailSettings1/:emailCode',
		component: asyncComponent(() =>
			import(
				/* webpackChunkName: "emailOptions" */ '../../js/components/email/emailOptions'
			)
		),
	},
];
