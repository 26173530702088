import asyncComponent from 'Demora/AsyncComponent';

let Authorize = asyncComponent(() => import(/* webpackChunkName: "prot-auth-oauth" */ './OAuth/Authorize'));

export default [

	{
		path: '/oauth/request',
		component: Authorize,

	}

];
