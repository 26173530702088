import React from 'react';

import { NavbarBrand } from 'reactstrap';

import ReduxConnect from 'Demora/redux-connect';

class TopNavBarLogo extends React.Component {
	render() {
		return (
			<NavbarBrand style={{ marginLeft: '40px' }}>
				<img src='/assets/logo_3.jpg' />
			</NavbarBrand>
		);
	}
}

export default ReduxConnect(TopNavBarLogo, {
	user: 'user',
});
